<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" md="12" sm="12">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="2"
                                                    label-for="fiscal_year_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('barc_config.fiscal_year') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="education.fiscal_year_id"
                                                        :options="fiscalYearList"
                                                        id="type_id"
                                                        style="width:20%"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <b-row>
                                                <b-col xl="12" lg="12" sm="12">
                                                    <h4 class="text-dark" style="font-weight:500;margin-bottom:20px;">{{ $t('barc_config.academic_record_score_info') }}</h4>
                                                </b-col>
                                            </b-row>
                                            <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px;" class="text-dark">
                                                <b-col lg="4">
                                                    <p>{{ $t('barc_config.education_level') }}</p>
                                                </b-col>
                                                <b-col lg="2">
                                                    <p>{{ $t('barc_config.score_system') }}</p>
                                                </b-col>
                                                <b-col lg="2">
                                                    <p>{{ $t('barc_config.class_grade') }}</p>
                                                </b-col>
                                                <b-col lg="2">
                                                    <p>{{ $t('barc_config.point') }}</p>
                                                </b-col>
                                                <b-col lg="2">
                                                    <p>{{ $t('globalTrans.action') }}</p>
                                                </b-col>
                                            </b-row>
                                            <b-row v-for="(detail,index) in education.details" :key="index">
                                                <b-col xl="4" lg="4" sm="6">
                                                    <ValidationProvider name="Education Level" vid="education_level_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            label-for="education_level_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-select
                                                                plain
                                                                v-model="detail.education_level_id"
                                                                :options="educationLevelList"
                                                                id="education_level_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xl="2" lg="2" sm="6">
                                                    <ValidationProvider name="Score System Id" vid="score_system_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            label-for="score_system_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-select
                                                                plain
                                                                v-model="detail.score_system_id"
                                                                :options="scoreSystemList"
                                                                @change="getClassGradeList(index)"
                                                                id="score_system_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xl="2" lg="2" sm="6">
                                                    <ValidationProvider name="Class Or Grade Id" vid="class_or_grade_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            label-for="class_or_grade_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-select
                                                                plain
                                                                v-model="detail.class_or_grade_id"
                                                                :options="detail.classGradeList"
                                                                id="class_or_grade_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xl="2" lg="2" sm="6">
                                                    <ValidationProvider name="Point" vid="point" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <b-form-input
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                v-model="detail.point"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xl="2" lg="2" sm="6">
                                                    <b-button v-show="index == education.details.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                                                    <b-button v-show="index || ( !index && education.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ssAssessEduStore, ssAssessEduUpdate, ssAssessEduShow } from '../../api/routes'

export default {
    props: ['id', 'fiscalYearId'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            education: {
                fiscal_year_id: 0,
                details: [
                    {
                        education_level_id: 0,
                        score_system_id: 0,
                        class_or_grade_id: 0,
                        point: '',
                        classGradeList: []
                    }
                ]
            },
            tmpData: {},
            scoreSystemList: [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'পুরাতন' : 'Old' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'নতুন' : 'New' }
            ],
            divisionList: [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'প্রথম বিভাগ' : 'First Division'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় বিভাগ' : 'Second Division'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'তৃতীয় বিভাগ' : 'Third Division'
                }
            ],
            classList: [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'প্রথম শ্রেণী' : 'First Class'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় শ্রেণী' : 'Second Class'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'তৃতীয় শ্রেণী' : 'Third Class'
                }
            ],
            gradeList: [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'এ+' : 'A+'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'এ' : 'A'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'এ-' : 'A-'
                },
                {
                    value: 4,
                    text: this.$i18n.locale === 'bn' ? 'বি' : 'B'
                },
                {
                    value: 5,
                    text: this.$i18n.locale === 'bn' ? 'সি' : 'C'
                },
                {
                    value: 6,
                    text: this.$i18n.locale === 'bn' ? 'ডি' : 'D'
                },
                {
                    value: 7,
                    text: this.$i18n.locale === 'bn' ? 'এফ' : 'F'
                }
            ]
        }
    },
    created () {
        if (this.id && this.fiscalYearId) {
            this.getAssessmentEducation(this.fiscalYearId)
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        fiscalYearList: function () {
            return this.$store.state.commonObj.fiscalYearList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        educationLevelList: function () {
            return this.$store.state.incentiveGrant.commonObj.educationLevelList
        }
    },
    methods: {
        getAssessmentEducation (fiscalYearId) {
            this.loading = true
            RestApi.getData(incentiveGrantServiceBaseUrl, ssAssessEduShow + '/' + fiscalYearId)
            .then(response => {
                const list = Object.assign({}, this.tmpData, {
                    fiscal_year_id: response.data[0].fiscal_year_id,
                    details: response.data
                })
                this.education = list
                list.details.map((item, index) => {
                    this.getClassGradeList(index)
                })
                this.loading = false
            })
        },
        getClassGradeList (index) {
            const currentIndex = this.education.details[index]
            const educationLevelId = currentIndex.education_level_id
            const scoreSystemId = currentIndex.score_system_id
            if (scoreSystemId === 1) {
                if (educationLevelId > 2) {
                    this.education.details[index].classGradeList = this.classList
                } else {
                    this.education.details[index].classGradeList = this.divisionList
                }
            } else {
                this.education.details[index].classGradeList = this.gradeList
            }
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                const formData = Object.assign(this.education, { id: this.id })
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${ssAssessEduUpdate}/${this.id}`, formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, ssAssessEduStore, this.education)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        add () {
            const tmp = {
                education_level_id: 0,
                score_system_id: 0,
                class_or_grade_id: 0,
                point: '',
                classGradeList: []
            }
            const key1 = parseInt(this.education.details.length - 1)
            const item = this.education.details[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.education.details.push(tmp)
            }
        },
        remove (key) {
            this.education.details.splice(key, 1)
        }
    }
}
</script>
