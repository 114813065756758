<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
              <template>
                <b-overlay :show="loading">
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-row>
                        <b-col lg="12" sm="12">
                          <div>
                            <b-table-simple striped bordered small>
                              <b-tr>
                                <b-th>{{ $t('barc_config.fiscal_year') }}</b-th>
                                <b-td style="width:70%">{{ education.fiscal_year }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-row>
                              <b-col lg="12" sm="12" class="text-center mb-4">
                                <h5 class='complain-title'>{{ $t('barc_config.academic_record_score_info') }}</h5>
                              </b-col>
                            </b-row>
                            <b-table-simple striped bordered small>
                              <b-tr>
                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th>{{ $t('barc_config.education_level') }}</b-th>
                                <b-th>{{ $t('barc_config.score_system') }}</b-th>
                                <b-th>{{ $t('barc_config.class_grade') }}</b-th>
                                <b-th>{{ $t('barc_config.point') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(detail, index) in education.details" :key="index">
                                <b-td>{{ $n(index + 1) }}</b-td>
                                <b-td>{{ getEducationLevelName(detail.education_level_id) }}</b-td>
                                <b-td>{{ getScoreSystemName(detail.score_system_id) }}</b-td>
                                <b-td>{{ getClassGradeList(detail.education_level_id, detail.score_system_id, detail.class_or_grade_id) }}</b-td>
                                <b-td>{{ $n(detail.point) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ssAssessEduShow } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id', 'fiscalYearId'],
  created () {
    if (this.id && this.fiscalYearId) {
      this.getAssessmentEducation(this.fiscalYearId)
    }
  },
  data () {
    return {
      loading: true,
      education: '',
      tmpData: {},
      scoreSystemList: [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'পুরাতন' : 'Old' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'নতুন' : 'New' }
      ],
      divisionList: [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'প্রথম বিভাগ' : 'First Division'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় বিভাগ' : 'Second Division'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'তৃতীয় বিভাগ' : 'Third Division'
        }
      ],
      classList: [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'প্রথম শ্রেণী' : 'First Class'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় শ্রেণী' : 'Second Class'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'তৃতীয় শ্রেণী' : 'Third Class'
        }
      ],
      gradeList: [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'এ+' : 'A+'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'এ' : 'A'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'এ-' : 'A-'
        },
        {
          value: 4,
          text: this.$i18n.locale === 'bn' ? 'বি' : 'B'
        },
        {
          value: 5,
          text: this.$i18n.locale === 'bn' ? 'সি' : 'C'
        },
        {
          value: 6,
          text: this.$i18n.locale === 'bn' ? 'ডি' : 'D'
        },
        {
          value: 7,
          text: this.$i18n.locale === 'bn' ? 'এফ' : 'F'
        }
      ]
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    }
  },
  methods: {
    getAssessmentEducation (fiscalYearId) {
      this.loading = true
      RestApi.getData(incentiveGrantServiceBaseUrl, ssAssessEduShow + '/' + fiscalYearId)
      .then(response => {
        const list = Object.assign({}, this.tmpData, {
          fiscal_year: this.getFiscalYear(response.data[0].fiscal_year_id),
          details: response.data
        })
        this.education = list
        this.loading = false
      })
    },
    getClassGradeList (educationLevelId, scoreTypeId, classGradeId) {
      if (scoreTypeId === 1) {
        if (educationLevelId > 2) {
          const classData = this.classList.find(cls => cls.value === classGradeId)
          return classData.text
        } else {
          const divisionData = this.divisionList.find(division => division.value === classGradeId)
          return divisionData.text
        }
      } else {
        const gradeData = this.gradeList.find(grade => grade.value === classGradeId)
        return gradeData.text
      }
    },
    getEducationLevelName (educationLevelId) {
      const level = this.$store.state.incentiveGrant.commonObj.educationLevelList.find(item => item.value === educationLevelId)
      return this.localeLang === 'bn' ? level.text_bn : level.text_en
    },
    getScoreSystemName (scoreSystemId) {
      const score = this.scoreSystemList.find(item => item.value === scoreSystemId)
      return score.text
    },
    getFiscalYear (fiscalYearId) {
      const fiscalYear = this.$store.state.commonObj.fiscalYearList.find(item => item.value === fiscalYearId)
      return fiscalYear.text_en
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
